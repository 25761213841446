<template>
  <div>
    <h3 class="doc-chapter__info__title">选择文档</h3>
    <div class="doc-chapter__info__select">
      <el-button type="primary" @click="showSelectDocDialog"
        >选择文档</el-button
      >
      <span>选择您想为视频添加的文档</span>
    </div>
    <div class="doc-chapter__info__select">
      <label
        :class="['upload-label', { disabled: !isCanUpload }]"
        :for="!isCanUpload ? '' : 'upload-doc'"
        >上传文档</label
      >
      <input
        type="file"
        id="upload-doc"
        @change="changeFile"
        accept="ppt/pptx"
        ref="uploadDocInput"
      />
      <span>仅支持上传PPT文档，限制大小100MB，200页</span>
    </div>
    <div class="doc-chapter__info__progress-wrap" v-if="file">
      <i class="icon-ppt"></i>
      <div class="doc-info">
        <div class="doc-info__label">
          <span>{{ file.name }}</span>
          <span>{{ upload_progress }}%</span>
        </div>
        <el-progress
          :percentage="upload_progress"
          :show-text="false"
        ></el-progress>
      </div>
    </div>
    <el-dialog :visible.sync="isShowSelectDocDialog" center title="选择文档">
      <p class="tips">*注意：若选择多个文档将按照文档ID顺序显示</p>
      <el-table
        :data="docList"
        :header-cell-style="tableHeadStyle"
        @selection-change="selectionChange"
        v-loading="loading"
        element-loading-text="加载中..."
        :row-class-name="tableRowClassName"
      >
        <el-table-column
          type="selection"
          :selectable="selectable"
        ></el-table-column>
        <el-table-column label="文档ID" prop="document_id"></el-table-column>
        <el-table-column
          label="文档名称"
          prop="file_name"
          min-width="130"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="大小" prop="size"></el-table-column>
        <el-table-column label="文档页数" prop="page"></el-table-column>
        <el-table-column label="状态">
          <template slot-scope="{ row }">
            <span>{{ row.trans_status | switchTransstatus }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          v-if="pagination['total']"
          layout="prev, pager, next"
          :total="pagination['total']"
          @current-change="changePage"
        ></el-pagination>
      </div>
      <div class="footer">
        <el-button type="primary" @click="selectDoc">确定</el-button>
        <el-button @click="closeDialog">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import eventBus from '../event'
import { APPID } from '../mixin'
export default {
  data() {
    return {
      isShowSelectDocDialog: false,
      tableHeadStyle: {
        backgroundColor: '#F1F2F6',
        color: '#666',
        fontWeight: 400
      },
      upload_progress: 0,
      docList: [],
      file: null,
      isCanUpload: true,
      loading: true,
      pagination: {
        total: 0,
        pageSize: 5,
        curPage: 1
      },
      selectDocIdArr: [],
      timer: null
    }
  },
  methods: {
    selectable(row, index) {
      return row.trans_status === 3
    },
    tableRowClassName({ row, rowIndex }) {
      let color = ''
      for (let item of this.selectDocIdArr.values()) {
        if (item === row.document_id) {
          color = 'table-SelectedRow-bgcolor'
        }
      }
      return color
    },
    getDocList() {
      const params = {
        app_id: APPID,
        page_size: this.pagination.pageSize,
        curr_page: this.pagination.curPage
      }
      this.$fetch('getDocList', params)
        .then(({ data }) => {
          this.loading = false
          this.docList = data.data.detail
          this.pagination.total = data.data.total
        })
        .catch(e => {
          console.error(e)
          this.loading = false
        })
    },
    selectionChange(row) {
      this.selectDocIdArr = row.map(item => item.document_id)
    },
    changePage(page) {
      this.pagination.curPage = page
      this.getDocList()
    },
    showSelectDocDialog() {
      this.pagination.total = 0
      this.pagination.curPage = 1
      this.docList = []
      this.getDocList()
      this.isShowSelectDocDialog = true
    },
    changeFile(e) {
      if (!this.isCanUpload) return false
      const file = e.target.files[0]
      const ext = file.name.split('.').pop()
      const allowExts = ['pptx', 'ppt']
      const maxFileSize = 1024 * 1024 * 100
      if (!allowExts.includes(ext)) {
        this.$message.info('允许上传文件类型为pptx、ppt')
        return
      } else if (file.size > maxFileSize) {
        this.$message.info('文件大于100MB，请重新选择')
        return
      }
      this.file = file
      this.$refs.uploadDocInput.value = ''
      this.uploadFile()
    },
    uploadFile() {
      const params = {
        app_id: APPID,
        file: this.file
      }
      const headers = {
        'Content-Type': 'multipart/form-data'
      }
      this.timer = setInterval(() => {
        if (this.upload_progress < 100) {
          this.upload_progress++
        }
      }, 100)
      this.$fetch('uploadDoc', params, headers)
        .then(res => {
          this.upload_progress = 100
          this.isCanUpload = false
          clearInterval(this.timer)
        })
        .catch(e => {
          this.upload_progress = 0
          this.isCanUpload = true
          clearInterval(this.timer)
          this.$message.error(e.msg)
        })
    },
    selectDoc() {
      if (!this.selectDocIdArr.length) {
        this.$message.warning('请选择文档')
        return
      }
      if (this.selectDocIdArr.length > 3) {
        this.$message.warning('最多只能选择3个文档')
        return
      }
      let selectDocNames = []
      this.docList.forEach(item => {
        if (this.selectDocIdArr.includes(item.document_id)) {
          selectDocNames.push({
            document_id: item.document_id,
            doc_name: item.file_name
          })
        }
      })
      this.isShowSelectDocDialog = false
      eventBus.$emit('getDocNames', {
        selectDocNames: selectDocNames
      })
      this.$router.push({
        name: 'chapter-edit',
        params: {
          isEdit: false,
          doc_ids: this.selectDocIdArr.join(',')
        }
      })
    },
    closeDialog() {
      this.isShowSelectDocDialog = false
    }
  },
  filters: {
    switchTransstatus(value) {
      const trans_statuss = {
        1: '待转码',
        2: '转换中',
        3: '转换成功',
        4: '转换失败'
      }
      return trans_statuss[value] || '未知'
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>

<style lang="less" scoped>
@import '../../../assets/less/varible.less';
/deep/ .table-SelectedRow-bgcolor {
  td {
    background-color: #eaf6ff !important;
  }
}
.doc-chapter__info {
  &__title {
    margin: 0;
    color: #333;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
  }
  &__select {
    display: flex;
    align-items: center;
    margin-top: 30px;
    font-size: 14px;
    .el-button,
    .upload-label {
      width: 120px;
      margin-right: 10px;
      &.disabled,
      &.disabled:hover {
        color: #fff;
        background: #dddddd;
        cursor: not-allowed;
      }
    }
    .upload-label {
      display: block;
      height: 40px;
      width: 120px;
      color: #fff;
      font-weight: 500;
      cursor: pointer;
      text-align: center;
      line-height: 41px;
      border-radius: 4px;
      background: @mainColor;
      &:hover {
        background: @btnHoverColor;
      }
    }
    #upload-doc {
      display: none;
    }
  }

  &__progress-wrap {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    .icon-ppt {
      display: block;
      width: 26px;
      height: 31px;
      background: url('../img/ppt.png') no-repeat;
      background-size: cover;
    }
    .doc-info {
      flex: 1;
      margin: 0 10px;
      max-width: 560px;
      &__label {
        display: flex;
        justify-content: space-between;
      }
      .el-progress {
        margin-top: 5px;
      }
    }
  }
}

/deep/ .el-dialog {
  width: 720px;
  min-height: 550px;
  box-shadow: 0px 11px 22px 0px rgba(0, 0, 0, 0.2),
    0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  .footer {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    margin-top: 30px;
    .el-button {
      width: 100px;
    }
  }
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
}
.tips {
  margin-bottom: 15px;
  color: #1e89e4;
  font-size: 15px;
}
.el-table {
  color: #333;
}
.el-button--text {
  padding: 0;
}
</style>
